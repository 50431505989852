import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Governance Call Notes Template`}</h1>
    <p>{`Use this template if you’re taking notes at a governance meeting.`}</p>
    <h2>{`Markdown`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`# Episode 00: Month 00, 2020

## Agenda

- [00:00](): Intro with Rich Brown
- [00:00](): Intro with Rich Brown
- [00:00](): Intro with Rich Brown
- [00:00](): Intro with Rich Brown

## Video

<>

## Introduction

### Rich

#### Agenda Summary and Talking Points

[00:00](link)

- Insert notes here
- Discussions happen in the [Forum.](https://forum.makerdao.com/)

#### Questions and Comments 1

[00:00](link)

- Insert question or comment here

[00:00](link)

- Insert question or comment here

## Main Theme

### Name of Person Presenting

#### Specific Subject

[00:00]()

screenshot
- notes

screenshot
- notes

screenshot
- notes

screenshot
- notes

#### Questions and Comments 2

[00:00](link)

- Insert question or comment here

[00:00](link)

- Insert question or comment here

## Analysis

### Vishesh

#### The State of the Pegs

[00:00](link)

- Insert beginning of notes here

#### Relevant Links

[MCD system stats](http://daistats.com)
[SCD system stats](http://saistats.com/)
[DAI & Sai 24hr VWAP Graphs](http://dai.descipher.io/)

#### Questions and Discussion 3

[00:00](link)

- Insert question or comment here

[00:00](link)

- Insert question or comment here

## Open Ended

### Open-ended Discussion

[00:00](link)

- Insert question or comment here

[00:00](link)

- Insert question or comment here

#### Relevant Links

- [xx](link)

## Common Abbreviated Terms

\`SCD\`: The Single-Collateral Dai system

\`MCD\`: The Multi-Collateral Dai system

\`DC\`: Debt Ceiling

\`ES\`: Emergency Shutdown

\`SF\`: Stability Fee

\`DSR\`: Dai Savings Rate

## Credits

- David Utrobin produced this summary.
- Igor Teslya produced this summary.
- Juan Guillen produced this summary.
- Gala Guillen produced this summary.
- Tim Black produced this summary.
- Everyone who spoke and presented on the call (listed in the headers.)
`}</code></pre>
    <h2>{`End result`}</h2>
    <p>{`Below is what the summary looks like on the site.`}</p>
    <h2>{`Agenda`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": ""
        }}>{`00:00`}</a>{`: Intro with Rich Brown`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": ""
        }}>{`00:00`}</a>{`: Intro with Rich Brown`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": ""
        }}>{`00:00`}</a>{`: Intro with Rich Brown`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": ""
        }}>{`00:00`}</a>{`: Intro with Rich Brown`}</li>
    </ul>
    <h2>{`Video`}</h2>
    <h2>{`Introduction`}</h2>
    <h3>{`Rich`}</h3>
    <h4>{`Agenda Summary and Talking Points`}</h4>
    <p><a parentName="p" {...{
        "href": ""
      }}>{`00:00`}</a></p>
    <ul>
      <li parentName="ul">{`Insert notes here`}</li>
      <li parentName="ul">{`Discussions happen in the `}<a parentName="li" {...{
          "href": "https://forum.makerdao.com/"
        }}>{`Forum.`}</a></li>
    </ul>
    <h4>{`Questions and Comments 1`}</h4>
    <p><a parentName="p" {...{
        "href": ""
      }}>{`00:00`}</a></p>
    <ul>
      <li parentName="ul">{`Insert question or comment here`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": ""
      }}>{`00:00`}</a></p>
    <ul>
      <li parentName="ul">{`Insert question or comment here`}</li>
    </ul>
    <h2>{`Main Theme`}</h2>
    <h3>{`Name of Person Presenting`}</h3>
    <h4>{`Specific Subject`}</h4>
    <p><a parentName="p" {...{
        "href": ""
      }}>{`00:00`}</a></p>
    <p>{`screenshot`}</p>
    <ul>
      <li parentName="ul">{`notes`}</li>
    </ul>
    <p>{`screenshot`}</p>
    <ul>
      <li parentName="ul">{`notes`}</li>
    </ul>
    <p>{`screenshot`}</p>
    <ul>
      <li parentName="ul">{`notes`}</li>
    </ul>
    <p>{`screenshot`}</p>
    <ul>
      <li parentName="ul">{`notes`}</li>
    </ul>
    <h4>{`Questions and Comments 2`}</h4>
    <p><a parentName="p" {...{
        "href": ""
      }}>{`00:00`}</a></p>
    <ul>
      <li parentName="ul">{`Insert question or comment here`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": ""
      }}>{`00:00`}</a></p>
    <ul>
      <li parentName="ul">{`Insert question or comment here`}</li>
    </ul>
    <h2>{`Main Theme`}</h2>
    <h3>{`Name of Person Presenting`}</h3>
    <h4>{`Specific Subject`}</h4>
    <p><a parentName="p" {...{
        "href": ""
      }}>{`00:00`}</a></p>
    <p>{`screenshot`}</p>
    <ul>
      <li parentName="ul">{`notes`}</li>
    </ul>
    <p>{`screenshot`}</p>
    <ul>
      <li parentName="ul">{`notes`}</li>
    </ul>
    <p>{`screenshot`}</p>
    <ul>
      <li parentName="ul">{`notes`}</li>
    </ul>
    <p>{`screenshot`}</p>
    <ul>
      <li parentName="ul">{`notes`}</li>
    </ul>
    <h4>{`Questions and Comments 3`}</h4>
    <p><a parentName="p" {...{
        "href": ""
      }}>{`00:00`}</a></p>
    <ul>
      <li parentName="ul">{`Insert question or comment here`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": ""
      }}>{`00:00`}</a></p>
    <ul>
      <li parentName="ul">{`Insert question or comment here`}</li>
    </ul>
    <h2>{`Analysis`}</h2>
    <h3>{`Vishesh`}</h3>
    <h4>{`The State of the Pegs`}</h4>
    <p><a parentName="p" {...{
        "href": ""
      }}>{`00:00`}</a></p>
    <ul>
      <li parentName="ul">{`Insert beginning of notes here`}</li>
    </ul>
    <h4>{`Relevant Links`}</h4>
    <p><a parentName="p" {...{
        "href": "http://daistats.com"
      }}>{`MCD system stats`}</a>{` `}<a parentName="p" {...{
        "href": "http://saistats.com/"
      }}>{`SCD system stats`}</a>{` `}<a parentName="p" {...{
        "href": "http://dai.descipher.io/"
      }}>{`DAI & Sai 24hr VWAP Graphs`}</a></p>
    <h4>{`Questions and Discussion 3`}</h4>
    <p><a parentName="p" {...{
        "href": ""
      }}>{`00:00`}</a></p>
    <ul>
      <li parentName="ul">{`Insert question or comment here`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": ""
      }}>{`00:00`}</a></p>
    <ul>
      <li parentName="ul">{`Insert question or comment here`}</li>
    </ul>
    <h2>{`Open Ended`}</h2>
    <h3>{`Open-ended Discussion`}</h3>
    <p><a parentName="p" {...{
        "href": ""
      }}>{`00:00`}</a></p>
    <ul>
      <li parentName="ul">{`Insert question or comment here`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": ""
      }}>{`00:00`}</a></p>
    <ul>
      <li parentName="ul">{`Insert question or comment here`}</li>
    </ul>
    <h4>{`Relevant Links`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": ""
        }}>{`xx`}</a></li>
    </ul>
    <h2>{`Common Abbreviated Terms`}</h2>
    <p><inlineCode parentName="p">{`SCD`}</inlineCode>{`: The Single-Collateral Dai system`}</p>
    <p><inlineCode parentName="p">{`MCD`}</inlineCode>{`: The Multi-Collateral Dai system`}</p>
    <p><inlineCode parentName="p">{`DC`}</inlineCode>{`: Debt Ceiling`}</p>
    <p><inlineCode parentName="p">{`ES`}</inlineCode>{`: Emergency Shutdown`}</p>
    <p><inlineCode parentName="p">{`SF`}</inlineCode>{`: Stability Fee`}</p>
    <p><inlineCode parentName="p">{`DSR`}</inlineCode>{`: Dai Savings Rate`}</p>
    <h2>{`Credits`}</h2>
    <ul>
      <li parentName="ul">{`Artem Gorden produced this summary.`}</li>
      <li parentName="ul">{`David Utrobin produced this summary.`}</li>
      <li parentName="ul">{`Igor Teslya produced this summary.`}</li>
      <li parentName="ul">{`Juan Guillen produced this summary.`}</li>
      <li parentName="ul">{`Gala Guillen produced this summary.`}</li>
      <li parentName="ul">{`Tim Black produced this summary.`}</li>
      <li parentName="ul">{`Everyone who spoke and presented on the call `}{`(`}{`listed in the headers.`}{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      